@import "./Variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,800&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100&display=swap');


html,
body {
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  color: var(--text-color);
  // font-family: 'Poppins', sans-serif;
  // font-family: 'Lato', sans-serif;
  font-family: 'Open Sans', sans-serif;


}

* {
  margin: 0;
  padding: 0;
}

.card-bg {
  background-color: var(--card-bg);
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 75px;
  background-color: var(--primary-color);
  position: sticky;
  top: 0;
  z-index: 100;

  .logo-wrap {
    .img-wrap {
      cursor: pointer;

      img {
        max-width: 194px;
        min-width: 165px;
      }
    }
  }

  .menu-wrap {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    ul {
      padding-left: 0;
      list-style: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      white-space: nowrap;
      column-gap: 20px;
      font-size: 14px;
      cursor: pointer;
      li {
        list-style: none;
        a {
          text-decoration: unset;
          color: var(--white-color);
          &:hover, &.active {
            color: var(--secondary-color);
          }
        }
      }
    }
    .menu-icon i {
      color: var(--text-color);
    }
  }
}

.banner {
  background-color: var(--primary-color);
  min-height: 60dvh;
  position: relative;
  display: flex;
  align-items: center;
  .design-asset {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    img {
      &.asset1 {
        position: relative;
        left: 60px;
        max-width: 250px;
        top: -168px;
      }
    }
  }
  .banner-graphic {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.banner-content {
  max-width: 540px;

  h2 {
    font-size: 36px;
    color: var(--text-color);
  }

  p {
    font-size: 14px;
    color: var(--text-color);
  }
}


.info-section {
  position: relative;
  .design-asset2 {
    position: absolute;
    left: -40px;
    top: -240px;
    width: 100%;
    img {
      .asset2 {
        max-width: 250px;

      }
    }
  }
  .left-single-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--card-bg);
    border-radius: 20px;
    padding: 0 40px;
    text-align: center;
    position: relative;
    margin-top: -75px;

    .img-wrap {
      z-index: 1;

      img {
        width: 180px;
        margin-bottom: 35px;
      }
    }

    .content {
      z-index: 1;
    }

    &::before {
      content: '';
      background-color: var(--card-bg);
      width: 100%;
      height: calc(100% + 60px);
      position: absolute;
      z-index: 0;
      border-radius: 20px;
    }
  }

  .info-card {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--card-bg);
    border-radius: 20px;
    padding: 0 40px 0 20px;
    text-align: center;
    min-height: 120px;
    .content {
      p {
        text-align: left;
      }
    }

    .icon-wrap {
      width: 90px;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {}

      i {
        font-size: 43px;
      }
    }
  }

  .content {
    border-radius: 20px;

    h2 {
      font-size: 23px;
      margin: 0;
      text-align: left;
    }

    p {
      font-size: 14px;
      margin: 0;
    }
  }
}


.parallax-section {
  background-image: url(https://images.pexels.com/photos/2653362/pexels-photo-2653362.jpeg);
  min-height: 450px;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::before {
    content: '';
    background-color: var(--card-bg);
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
  }

  .content {
    max-width: 60%;
    z-index: 1;
    text-align: center;
  }
}

.company-information-section {
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .info-card {
    background-color: var(--card-bg);
    border-radius: 20px;
    height: 100%;
    padding: 25px;
    font-size: 14px;

    h3 {
      font-size: 25px;
    }

    ul {
      padding-left: 15px;
    }
  }
}


.tech-stack-section {
  min-height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 45px;


  .section-title {
    h2 {
      font-size: 32px;
    }
  }

  .techstack-wrap {
    display: flex;
    overflow: auto;
    scroll-snap-type: x mandatory;

    .mobileCol {
      min-width: calc(100% / 6);
      scroll-snap-align: start;

      h2 {
        text-align: center;
        font-size: 21px;
        margin-top: 10px;
      }

      .img-wrap {
        img {
          width: 90px;
          display: block;
          margin: auto;
        }
      }
    }

    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          .img-wrap {
            cursor: pointer;

            img {
              max-width: 100%;
              /* max-height: 100%; */
              /* width: 115px; */
              /* height: 115px; */
              aspect-ratio: 1/1;
              display: block;
              margin: auto;
              object-fit: contain;
              background-color: var(--card-bg);
              border-radius: 10px;
              padding: 20px;
            }
          }
          h2 {
            font-size: 12px;
            text-align: center;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.contact-section {
  .content {
    z-index: 1;
    position: relative;
    max-width: 80%;
    height: 100%;
    display: flex;
    align-items: center;

    h3 {
      color: var(--white-color);
      text-align: left;
    }
  }

  .contact-form-wrap {
    z-index: 1;
    position: relative;

    .input-wrap {
      width: 100%;
      margin-bottom: 10px;

      input,
      textarea {
        width: 100%;
        min-height: 50px;
        background-color: var(--input-color);
        border: none;
        outline: none;
        color: var(--white-color);
        padding: 5px 10px;
        font-size: 12px;
      }
    }

    .submit-btn {
      min-width: 180px;
      border: none;
      outline: none;
      min-height: 45px;
      margin: 0;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.footer-section {
  padding: 15px 0;
  background-color: var(--black-color);
}

.scrollToTop {
  position: fixed;
  right: 25px;
  bottom: 40px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  z-index: 100;
  cursor: pointer;
  opacity: 0.5;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }
}




@media only screen and (max-width: 820px) {
  .parallax-section .content {
    max-width: 90%;
  }
  .info-section {
    .left-single-card {
      box-sizing: border-box;
      padding: 20px;
      height: auto;
      .img-wrap {
        img {
          width: 140px;
        }
      }
      &::before {
        display: none;
      }
    }
  }
//   .info-section .design-asset2  {
//     position: absolute;
//     left: -40px;
//     top: -206px;
//     width: 100%;

// img {
//   max-width: 150px;
//   transform: scaleY(-1);
// }
//   }
  .banner {
    .design-asset {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      img {
        &.asset1 {
          position: relative;
          left: 0;
          max-width: 100px;
          top: -200px;
          transform: scaleX(-1);
      }
      }
  }
    .banner-graphic {
      img {
        width: 100%;
      }
    }
    .banner-content {
      margin-top: -135px;
      max-width: 385px;
    }
  }

  
}
@media only screen and (max-width: 540px) {
  .menu-wrap {
    display: none !important;
  }
  .banner .banner-content {
    margin-top: -135px;
    max-width: calc(100% - 85px);
    position: relative;
    z-index: 10;
  }
}